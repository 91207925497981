import * as React from "react";
import { Anchor, Box, Button, Form, FormField, Heading, Paragraph, Select, SelectMultiple, TextArea, TextInput } from "grommet";

const ContactPage = () => {

  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [subject, setSubject] = React.useState({});
  const [contactMethod, setContactMethod] = React.useState({});
  const [questions, setQuestions] = React.useState('');
  const [whereUs, setWhereUs] = React.useState('');

  const contactMethods = [
    'Telefone',
    'E-mail',
    'WhatsApp'
  ].sort();

  const subjects = [
    'Architecture',
    'Automação Marketing',
    'Artificial Intelligence',
    'Analytics | Science',
    'BI | Self Service BI',
    'Big Data',
    'Branding',
    'Block Chain',
    'Compliance',
    'CRO',
    'Cyber Security',
    'Data Lake',
    'Engineering',
    'Governance',
    'Growth Hacking',
    'IoT',
    'Inbound Marketing',
    'Strategy',
    'SEO',
    'RPA | Bots',
    'Management',
    'Mídia Paga',
    'Marketing Conteúdo',
    'Visualization',
    'Plan & Develop',
    'Build & Code Quality',
    'Test',
    'DevSecOps',
    'Outsourcing',
    'Body Shop',
    'DevSecOps',
    'Capacitação',
    'Tribe RH',
    'Here’s My Copy',
    'Inhabitant',
    'Research Hub',
    'Digital Research Platform',
    'Outros assuntos',
  ].sort();

  const whereUsOptions = [
    'Indicação de um funcionário do grupo Data Marketplace',
    'Indicação de um amigo/colega/familiar',
    'Redes Sociais (LinkedIn, Facebook, Instagram)',
    'Pesquisa na internet (Google ou outra ferramenta de busca)',
    'Eventos (Presenciais ou Digitais)',
    'Outros'
  ];

  const handleSubmit = () => {

    const data = {
      "subject": "Contato de " + name,
      "text": { name, email, phone, subject, contactMethod, questions, whereUs },
  }

    fetch('https://l9ow3mntjl.execute-api.us-east-2.amazonaws.com/sendemail', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    });

    //  TODO: Send email
    alert("Obrigado por entrar em contato conosco, retornaremos em breve.");

    window.location.href = '/';

  }

  return (
    <>
      <Heading level={2} style={{color:"#3600b2"}}>Contato</Heading>
      <Paragraph fill>
      Estamos prontos para fazer você ter sucesso no âmbito estratégico da sua Transformação Digital.<br/> Diga-nos como podemos ajudar sua empresa.<br/><br/>Se desjar você pode <Anchor target="_blank" href="https://calendly.com/datamarketplace/firstcall">agendar uma primeira reuniao</Anchor> clicando <Anchor target="_blank" href="https://calendly.com/datamarketplace/firstcall">aqui</Anchor>.
      </Paragraph>
      <Box style={{marginTop: "30px"}}>
        <Form className="contact" method="post" action="#" onSubmit={handleSubmit}>

        {/* Name */}
        <FormField name="name" htmlFor="input-name" label="Nome e sobrenome">
          <TextInput 
            id="input-name"
            name="name"
            placeholder="Seu nome"
            onChange={e => setName(e.target.value)}
            minLength={4}
            maxLength={30}
            required 
          />
        </FormField>
        
        {/* Email */}
        <FormField name="email" htmlFor="input-email" label="E-mail">
          <TextInput 
            id="input-email"
            type="email"
            name="email"
            placeholder="voce@suaempresa.com"
            onChange={e => setEmail(e.target.value)}
            maxLength={80}
            minLength={4}
            required
            />
        </FormField>
        
        {/* Phone */}
        <FormField name="phone" htmlFor="input-phone" label="Número de telefone">
          <TextInput 
            id="input-phone"
            name="phone"
            placeholder="+5511999999999" 
            onChange={e => setPhone(e.target.value)}
            minLength={10}
            maxLength={20}
            required
            />
        </FormField>

        {/* Subject */}
        <FormField name="subject" htmlFor="input-subject" label="Assunto">
          <SelectMultiple
            required
            id="input-subject"
            placeholder="Selecione"
            options={subjects}
            onChange={e => setSubject(e.value)}
          />
        </FormField>

        {/* Contact Method */}
        <FormField name="contact-method" htmlFor="input-contact-method" label="Método de contato">
          <SelectMultiple
            required
            id="input-contact-method"
            placeholder="Selecione"
            options={contactMethods}
            onChange={e => setContactMethod(e.value)}
          />
        </FormField>

        {/* Questions  */}
        <FormField name="questions" htmlFor="input-questions" label="Perguntas e comentários">
          <TextArea
            required
            minLength={10}
            maxLength={3000}
            id="input-questions"
            rows={6}
            placeholder="Digite aqui..."
            value={questions}
            onChange={e => setQuestions(e.target.value)}
          />
        </FormField>

        <br/>
        {/* Where us */}
        <FormField name="where-us" htmlFor="input-where-us" label="Como nos conheceu?">
          <Select
            required
            id="input-where-us"
            options={whereUsOptions}
            value={whereUs}
            onChange={({ option }) => setWhereUs(option)}
          />
        </FormField>
        <br/>
        <br/>
        <Box direction="row" gap="medium">
          <Button type="submit" primary label="Enviar" />
        </Box>
      </Form>
      </Box>
    </>
  );
};

export default ContactPage;
